import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible'
import 'vant/lib/index.css';
//全局路由拦击
router.beforeEach((to, from, next) => {
	next();
})
const app=createApp(App)
//阻止启动时生成生产提示
//app.config.productionTip = false;
app.use(store).use(router).mount('#app')
