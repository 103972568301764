import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/index.vue'),
		meta: { requiresAuth:true }
	},
	
	{
	  path: '/home',
	  name: 'home',
	  component: () => import('@/views/home.vue'),
	  meta: { requiresAuth: true }
	}
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
